.ministry-page {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.ministry-content {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.ministry-introduction, .ministry-departments {
  margin-bottom: 50px;
}

h2 {
  color: #0f68b5;
  margin-bottom: 20px;
}

h3 {
  margin-top: 20px;
  color: #333;
}

ul {
  list-style: inside disc;
  margin-top: 10px;
}
.department-image {
  height:250px;
  display: block;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  max-width: 400px; 
  margin: 10px 10px;
}

.image-wrapper{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1%;
  justify-content: center;
}
/* Responsive adjustments */
@media (max-width: 768px) {

  .ministry-content {
    padding: 15px;
  }
}