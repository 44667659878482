
.navbar {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  background-color: white;
  padding: 20px 200px;
  transition: box-shadow 0.3s ease-in-out;
  overflow: visible; 
}

.navbar.sticky {
  position: sticky;
  top: 0; 
  z-index: 2000; 

}
.add-shadow{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px
}

.navbar__logo img {
  height: 60px;
}

.navbar__links {
  padding-left: 50px;
  display: flex;
  gap: 20px;
  flex-grow: 1; 
}

.navbar__links a, .dropbtn {
  text-decoration: none;
  color: #333;
  padding: 10px 15px;
  display: inline-block;
}

.navbar__links a:hover, .dropbtn:hover {
  color: #00BFFF; 
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 2000; 
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 2000; /* Adjusted from 1 to 3 */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.navbar__language-switch button {
  background: none;
  border: none;
  cursor: pointer;
}
.active-lang {
  font-weight: bold;
}

#hamburger-icon{
  height: 24px;
}
.navbar__menu-icon {
  display: none;
  cursor: pointer;
}

.navbar__popup-menu {
  position: fixed;
  top: 0;
  left: -100%; 
  width: 250px;
  height: 100%;
  background-color: white;
  transition: left 0.3s ease;
  z-index: 1000; 
}

.navbar__popup-menu.open {
  left: 0; 
}

.popup-menu-links {
  margin-top: 70px;
  display: flex;
  flex-direction: column; 
  align-items: start;
  padding-left: 20px;
  width: 90%; 
}

.popup-menu-links a {
  text-decoration: none;
  color: #333; 
  padding: 10px 0; 
  width: 100%;
}

.popup-menu-links a:hover {
  color: #00BFFF; 
}
.popup-drop{
  margin-left: 15px;
}

.close-button {
  position: absolute;
  top: 30px;
  right: 30px; 
  cursor: pointer;
  transition: transform 0.3s ease;
  padding-bottom: 20px;
}

.close-button img {
  width: 24px; 
  height: 24px; 
}

.close-button:hover img {
  transform: scale(1.1); 
  content: url('./closeBlue.png'); 
}

@media (max-width: 768px) {
  .navbar__links{
    display: none;
  }
  .navbar__menu-icon {
    display: block;
  }
  .navbar {
    padding: 20px; /* Reduced side padding */
  }
  
  .navbar__logo, .navbar__language-switch, #hamburger-icon {
    margin: 0 10px; /* Adds more space around these elements */
  }
}

