.church-banner{
  width: 100%;
  height: 300px; 
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.church-detail-page .church-banner {
  width: 100%;
  height: 400px; 
}

.church-detail-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  max-width: 800px;
  margin: auto;
}

.church-detail-text {
  width: 100%;
  margin-bottom: 30px; 
}

.church-image {
  height:250px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-color: lightgray;
  border-style: solid;
  border-width: 1px;
  width: 100%; 
  max-width: 400px; 
  margin: 10px auto;
}

@media (max-width: 768px) {
  .church-detail-content {
    padding: 20px;
  }

  .church-image {
    width: 90%; 
  }
}