.footer {
  padding: 20px 200px;
  background: linear-gradient(135deg, #F2F2F2, #E1E9F2);
  color: #010B40;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  #footer-logo {
    height: 75px;
  }
  
  .SocialMediaIco{
    height: 20px;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
  }

  @media (max-width: 768px) {
    .footer {
        padding: 20px; 
    }

    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center; 
    }

    .footer-section {
      width: 100%;
      margin-bottom: 20px;
    }

    .footer-contact{
      flex-direction: row;
      justify-content: space-around;
      display: flex;
    }

    .footer-logo, .footer-links, .footer-social-media {
        position: center;
        margin-bottom: 20px;
    }

}
  