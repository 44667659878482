.news-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .news-block {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin: 0 10px; 
  }

   .slick-slide {
    padding: 0 27px; 
    box-sizing: border-box; 
  }
  
  .news-block img {
    width: calc(100% - 54px); 
    height: auto;
    border-radius: 5px;
  }
  
  .news-block:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .news-info {
    padding-top: 10px;
  }
  
  .news-info h3 {
    margin-bottom: 5px;
  }
  
  .news-info p {
    font-size: 0.9em;
    color: #666;
  }
  
  .news-date {
    color: #3079D9;
    font-style: italic;
  }
  
  .navigation {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-top: 30px;
  }
  
  .news-navigation {
    display: flex;
    justify-content: center; 
    align-items: center;
  }
  
  .navigation-button {
    padding: 10px 15px;
    margin: 0 10px;
    background-color: #2B88D9;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .navigation-button:hover {
    background-color: #296ec8;
    transform: translateY(-2px);
  }
  #nexzImg{
    transform: rotateY(180deg); 
  }

  @media (max-width: 768px) {
    .seeMoreButton {
      display: none; /* Hide the text */
    }
    .news-container{
      max-width: 85%;
    }
    .dummyDiv{
      display: none;
    }
    .navigation{
      margin-left: auto;
      justify-content: center; 
    }
  }