.banner {
    width: 100%;
    height: 400px; 
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .darker::before{
    background: rgba(0, 0, 0, 0.5);
  }
  
  .banner {
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
    text-align: center;
  }
  
  .banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  
  .banner-content {
    position: relative;
    z-index: 1;
    color: #fff; 
    text-align: center; 
    width: 30%; 
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7); 
  }
  
  .banner h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .banner p {
    font-size: 1.2rem;
    max-width: 600px;
    margin: auto;
  }
  .divider {
    z-index: 2;
    width: 45px; 
    height: 3px; 
    border-radius: 5px; 
    background: #34a0ff; 
    position: relative; 
    margin: 20px auto; 
  }
  
  .divider::before {
    z-index: 2;
    content: ''; 
    display: block;
    width: 17px; 
    height: 3px; 
    border-top: 3px dotted #34a0ff; 
    position: absolute;
    right: 50px; 
    top: 0; 
  }

  @media (max-width: 768px) {
    .banner{
      height: 200px;
    }
    .banner-content{
      width: 100%;
    }
    .banner h1 {
      font-size: 1.8rem;
      padding: 5px 15px;
    }
  
    .banner p {
      font-size: 1rem;
    }
  }