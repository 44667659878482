
.news-detail-page {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  font-family: 'Arial', sans-serif; 
}

.news-content p {
  line-height: 1.6;
  
}

.news-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 40px;
}

.news-article-image {
  width: calc(50% - 5px); 
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .news-detail-page {
    max-width: 100%;
    padding: 10px;
  }

  .news-header h1 {
    font-size: 24px;
  }

  .news-article-image {
    width: 100%; 
  }
}
